<template>
  <v-container fluid fill-height>
    <v-layout align-center justify-center column>
      <div>
        <img class="image" :src="image" alt="logo" />
      </div>
      <div class="text">Авторизация</div>
      <v-form ref="signin" class="login">
        <v-text-field
          v-model="input.email"
          class="input"
          outlined
          dense
          :rules="[$validate.required, $validate.email]"
        ></v-text-field>
        <v-text-field
          v-model="input.password"
          class="input"
          :type="showPass ? 'text' : 'password'"
          outlined
          dense
          :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
          :rules="[$validate.required, $validate.min]"
          @click:append="showPass = !showPass"
        ></v-text-field>
      </v-form>
      <v-btn color="#503CC3" dark class="btn" @click="login()">Войти</v-btn>
    </v-layout>
  </v-container>
</template>
<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      showPass: false,
      input: {
        password: "",
        email: "",
      },
      image: require("@/assets/AyanTitle.png"),
    };
  },
  methods: {
    ...mapActions({
      signIn: "Auth/SIGN_IN",
    }),
    async login() {
      if (this.$refs.signin.validate()) {
        let response = await this.signIn(this.input);
        if (response.type !== "error") {
          this.$cookie.set("token", "Bearer" + response.token);
          this.$cookie.set("refreshToken", JSON.stringify(response));
          this.$router.push("/");
        } else {
          this.$notify({
            group: "app",
            type: "error",
            title: "Ошибка",
            text: "Вы неправильно ввели логин или пароль",
          });
        }
      }
    },
  },
};
</script>
<style lang="scss">
.text {
  display: flex;
  align-items: center;
  width: 440px;
  height: 30px;
  border-radius: 30px;
  text-align: center;
  justify-content: center;
  color: white;
}
.input {
  width: 440px;
  border-radius: 10px;
  color: rgb(7, 7, 7);
  .v-input__slot {
    background-color: #e8f0fe !important;
  }
}
.btn {
  width: 440px;
  border-radius: 10px;
  transition: 0.3s ease;
  &:hover {
    transform: translateY(-3px);
  }
}
</style>
